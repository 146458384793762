import SharedPromotion from '@/shared/models/Promotion'
import $constants from '@/services/constants'
import _ from 'lodash'

export default class Promotion extends SharedPromotion {

    static async banner(additionalConfig = {}) {
        let config = _.defaultsDeep(additionalConfig, {
            url   : `/promotion/banner`,
            method: 'get',
            params: {
                trigger_type: $constants.getConstant('PROMOTION_TYPE_BANNER_CLICK'),
            },
        })

        let response = await this.request(config)

        if (!response)
            return

        return response.data ? response.data.map(d => new this(d)) : null
    }

    static triggerByCode(code, additionalConfig = { params: {} }) {
        return this.request({
            url   : `/promotion/trigger`,
            method: 'post',
            params: {
                p: code,
                ...(additionalConfig.params || {}),
            },
            ..._.omit(additionalConfig, 'params'),
        })
    }

}